import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { ChatUI } from './ChatUI';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export const ChatContainer = ({ 
  socketData
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const isConnected = socketData.isConnected;
  const messages = socketData.messages;
  const sendMessage = socketData.sendMessage;

  // Handle initial messages load and updates
  useEffect(() => {
    if (messages.length > 0) {
      setIsLoading(false);
      setInitialLoadComplete(true);
    }
  }, [messages]);

  // Turn off loading if connection fails
  useEffect(() => {
    if (!isConnected) {
      setIsLoading(false);
    }
  }, [isConnected]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSendMessage = async (content) => {
    setIsLoading(true);
    
    try {
      await sendMessage(content);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  // Process messages to match the expected format of ChatUI
  const processedMessages = messages.map((msg, index) => ({
    id: msg.id || index,
    role: msg.role || 'user',
    content: msg.content
  }));

  return (
    <ChatUI
      messages={processedMessages}
      isConnected={isConnected}
      isLoading={isLoading}
      initialLoadComplete={initialLoadComplete}
      onSendMessage={handleSendMessage}
      isMobile={isMobile}
    />
  );
};
const ChatContainerWrapper = ({ requireAuthentication, ...props }) => {
  const Component = requireAuthentication
    ? withAuthenticationRequired(ChatContainer)
    : ChatContainer;
  return <Component requireAuthentication={requireAuthentication} {...props} />;
};

export default ChatContainerWrapper;