import React from 'react';
import { Paper, Typography, Box, Button } from '@mui/material';
import { UserCheck as UserCheckIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

const OnboardingCTA = () => (
  <Paper 
    elevation={1} 
    sx={{ 
      p: 2,
      mb: 2,
      backgroundColor: 'primary.lighter',
      borderLeft: '4px solid',
      borderColor: 'primary.main'
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <UserCheckIcon 
        size={20} 
        style={{ marginRight: '8px', color: 'gray' }} 
      />
      <Typography variant="h6">Complete Your Profile</Typography>
    </Box>
    <Typography 
      variant="body2" 
      color="text.secondary" 
      sx={{ mb: 2 }}
    >
      Your profile is incomplete. Take a few minutes to fill it out so headhunters and internal search teams can match you better.
    </Typography>
    <Button
      component={Link}
      to="/onboarding"
      variant="contained"
      color="primary"
    >
      Complete Profile Now
    </Button>
  </Paper>
);

export default OnboardingCTA;