// components/chat/Progress.jsx
import React from 'react';
import { 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon,
  ListItemButton,
  useTheme
} from '@mui/material';
import CheckIcon from '@mui/icons-material/TaskAltRounded';
import EllipsesIcon from '@mui/icons-material/MoreHorizTwoTone';
import EditingIcon from '@mui/icons-material/AppRegistrationTwoTone';

const Progress = ({ summary = [], onStageClick }) => {
  const theme = useTheme();

  const getIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckIcon color="success" />;
      case 'active':
        return <EditingIcon color="primary" />;
      case 'in_progress':
        return <EditingIcon color="action" />;
      default:
        return <EllipsesIcon color="action" />;
    }
  };

  const getItemStyle = (status) => ({
    '&.Mui-disabled': {
      opacity: 0.6,
      cursor: 'not-allowed'
    },
    '&:hover': {
      bgcolor: 'action.hover'
    },
    ...(status === 'active' && {
      bgcolor: theme.palette.primary.light + '20',
    })
  });

  return (
    <List>
      {summary.length > 0 ? (
        summary.map(([key, status], index) => (
          <ListItemButton
            key={index}
            onClick={() => onStageClick(key)}
            sx={getItemStyle(status)}
          >
            <ListItemText
              primary={`${key.charAt(0).toUpperCase()}${key.slice(1)}`}
              primaryTypographyProps={{
                variant: 'body2',
                color: status === 'active' ? 'primary' : 'inherit'
              }}
            />
            <ListItemIcon>
              {getIcon(status)}
            </ListItemIcon>
          </ListItemButton>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No progress data available..." />
        </ListItem>
      )}
    </List>
  );
};

export default Progress;