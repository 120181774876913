import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import useHasAccount from './useHasAccount';
import useAPIWithAuth from './useAPIWithAuth';

export const useAuthStatus = () => {
  const { isAuthenticated, isLoading: authLoading, user } = useAuth0();
  const { hasAccount } = useHasAccount(isAuthenticated);
  const api = useAPIWithAuth();

  useEffect(() => {
    if (user && isAuthenticated) {
      api.put('/profile/', {
        email: user.email,
        given_name: user.given_name, 
        family_name: user.family_name,
        picture: user.picture
      });
    }
  }, [user, isAuthenticated, api]);

  const isLoading = authLoading || (isAuthenticated && hasAccount === undefined);
  const hasValidAccount = isAuthenticated && hasAccount === "ready";
  const shouldWaitlist = isAuthenticated && hasAccount !== undefined && hasAccount !== "ready";

  return {
    isLoading,
    isAuthenticated,
    hasValidAccount,
    shouldWaitlist,
    user,
    hasAccount
  };
};