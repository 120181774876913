import { useState, useEffect, useRef } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export const ChatInput = ({
  onSendMessage,
  isLoading,
  disabled = false,
  initialLoadComplete = false
}) => {
  const [input, setInput] = useState('');
  const inputRef = useRef(null);
  const hasLoadedRef = useRef(false);

  // Keep the initial load focus behavior
  useEffect(() => {
    if (initialLoadComplete && !hasLoadedRef.current) {
      hasLoadedRef.current = true;
      inputRef.current?.focus();
    }
  }, [initialLoadComplete]);

  // Add focus when loading state changes from true to false
  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  const handleSend = async () => {
    if (input.trim() === '') return;
    onSendMessage(input);
    setInput('');
  };

  return (
    <>
      <div style={{ padding: '1rem' }}>
        <TextField
          fullWidth
          variant="outlined"
          multiline
          maxRows={4}
          label="Type a message"
          value={input}
          inputRef={inputRef}
          autoFocus
          readOnly={isLoading}
          disabled={disabled}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          disabled={isLoading || disabled}
          style={{ marginTop: '1rem' }}
        >
          {isLoading ? (
            <>
              <CircularProgress size={20} color="inherit" style={{ marginRight: 8 }} />
              Sending...
            </>
          ) : (
            <>
              Send
              <SendIcon style={{ marginLeft: 8 }} />
            </>
          )}
        </Button>
      </div>
    </>
  );
};