import React from 'react';
import { Stack, Paper, Typography } from '@mui/material';
import useAPIWithAuth from '../../hooks/useAPIWithAuth';
import { ProfileContainer } from './ProfileContainer';
import { useProfile } from './useProfile';
import { useProfileSections } from './useProfileSections';
import OnboardingCTA from './sections/OnboardingCTA';
import DataControlsBanner from './DataControlsBanner';

const Profile = () => {
  const api = useAPIWithAuth();
  const fetchProfile = React.useCallback(async () => {
    const response = await api.get('/profile/');
    return response.data;
  }, [api]);

  const { profile, error, loading } = useProfile(fetchProfile);
  const sections = useProfileSections(profile);

  const renderProfileData = () => {
    return (
      <Stack spacing={2}>
        {!profile?.has_onboarded && <OnboardingCTA />}
        {profile?.has_onboarded && <DataControlsBanner />}
        {sections.map((section) => {
          const Component = section.component;
          return (
            <Paper
              key={section.title}
              elevation={1}
              sx={{ p: 2 }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 2 }}
              >
                {section.title}
              </Typography>
              <Component {...section.props} />
            </Paper>
          );
        })}
      </Stack>
    );
  };

  return (
    <ProfileContainer loading={loading && !profile} error={error}>
      {sections.length > 0 && renderProfileData()}
    </ProfileContainer>
  );
};

export default Profile;