import { useMemo } from 'react';
import CurrentRoles from './sections/CurrentRoles';
import LocationPreferences from './sections/LocationPreferences';
import OrganisationPreferences from './sections/OrganisationPreferences';
import ContactPreferences from './sections/ContactPreferences';
import DataControl from './sections/DataControl';

const PROFILE_SECTIONS = [
  {
    component: CurrentRoles,
    propKey: 'experiences',
    dataKey: 'experiences',
    title: "Primary role(s)",
    stage: 'employment',
  },
  {
    component: OrganisationPreferences,
    propKey: 'preferences',
    dataKey: 'organisation_preferences',
    title: "Organisation Preferences",
    stage: 'organisation preferences',
  },
  {
    component: LocationPreferences,
    propKey: 'preferences',
    dataKey: 'location_preferences',
    title: "Location Preferences",
    stage: 'location preferences',
  },
  {
    component: ContactPreferences,
    propKey: 'details',
    dataKey: 'contact_preferences',
    title: "Contact Preferences",
    stage: 'contact preferences',
  },
  {
    component: DataControl,
    propKey: 'details',
    dataKey: 'data_control',
    title: "Data Control",
    stage: 'data control',
  }
];


const createSection = (sectionConfig, profileData) => ({
  ...sectionConfig,
  props: { [sectionConfig.propKey]: profileData[sectionConfig.dataKey] }
});

export const useProfileSections = (profile) => {
  return useMemo(() => {
    if (!profile) return [];

    return PROFILE_SECTIONS
      .map(section => createSection(section, profile));
  }, [profile]);
};