import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Chip
} from '@mui/material';
import { 
  Phone as PhoneIcon,
  Mail as EmailIcon,
  Settings as PreferencesIcon,
  Redo as FollowUpIcon,
  Star as PrimaryIcon,
  Info as NotesIcon
} from 'lucide-react';
import EmptyState from './EmptyState';
import { hasNonNullValues } from '../utils/hasNonNullValues';

const Section = ({ title, icon: Icon, children, divider = true }) => {
  if (!children) return null;
  return (
    <>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1.5 }}>
          <Icon size={18} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
      {divider && <Divider sx={{ my: 2 }} />}
    </>
  );
};

const PhoneNumbersList = ({ numbers }) => {
  if (!numbers?.length) return null;

  return (
    <List dense disablePadding>
      {numbers.map((phone, index) => (
        <ListItem 
          key={index} 
          disableGutters
          sx={{ py: 0.5 }}
        >
          <ListItemIcon sx={{ minWidth: 32 }}>
            <PhoneIcon size={16} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2">
                  {phone.number}
                </Typography>
                <Chip 
                  label={phone.label} 
                  size="small" 
                  variant="outlined"
                  sx={{ height: 20 }}
                />
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const EmailAddressList = ({ emails }) => {
  if (!emails?.length) return null;

  return (
    <List dense disablePadding>
      {emails.map((email, index) => (
        <ListItem 
          key={index} 
          disableGutters
          sx={{ py: 0.5 }}
        >
          <ListItemIcon sx={{ minWidth: 32 }}>
            <EmailIcon size={16} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2">
                  {email.email}
                </Typography>
                <Chip 
                  label={email.label} 
                  size="small" 
                  variant="outlined"
                  sx={{ height: 20 }}
                />
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const PreferencesSection = ({ preferences }) => {
  if (!preferences) return null;

  return (
    <List dense disablePadding>
      <ListItem disableGutters sx={{ py: 0.5 }}>
        <ListItemIcon sx={{ minWidth: 32 }}>
          <PrimaryIcon size={16} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2">
              Primary: {preferences.primary_method}
            </Typography>
          }
        />
      </ListItem>

      {preferences.follow_up_method && (
        <ListItem disableGutters sx={{ py: 0.5 }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <FollowUpIcon size={16} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">
                Secondary: {preferences.follow_up_method}
              </Typography>
            }
          />
        </ListItem>
      )}

      {preferences.additional_notes && (
        <ListItem disableGutters sx={{ py: 0.5 }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <NotesIcon size={16} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" color="text.secondary">
                {preferences.additional_notes}
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

const ContactPreferences = ({ details }) => {
  if (!hasNonNullValues(details)) {
    return (
      <EmptyState 
        icon={PreferencesIcon}
        title="No contact preferences set"
        description="Add your preferred contact methods and when you'd like to be reached"
      />
    );
  }

  return (
    <Stack>
      {/* Phone Numbers */}
      <Section title="Phone Numbers" icon={PhoneIcon}>
        <PhoneNumbersList numbers={details.phone_numbers} />
      </Section>

      {/* Email Addresses */}
      <Section title="Email Addresses" icon={EmailIcon}>
        <EmailAddressList emails={details.email_addresses} />
      </Section>

      {/* Contact Preferences */}
      <Section 
        title="Contact Preferences" 
        icon={PreferencesIcon} 
        divider={false}
      >
        <PreferencesSection preferences={details.contact_preference} />
      </Section>
    </Stack>
  );
};

export default ContactPreferences;