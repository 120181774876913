import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Stack,
  Collapse,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('default', { month: 'short', year: 'numeric' });
  } catch {
    return dateString;
  }
};

const PreviousExperience = ({ experiences }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const previousRoles = experiences?.filter(exp => 
    exp.end_date && exp.end_date.toLowerCase() !== 'current'
  ) || [];

  if (previousRoles.length === 0) return null;

  // Get unique organizations
  const uniqueOrgs = [...new Set(previousRoles.map(exp => exp.organisation))];
  const topOrgs = uniqueOrgs.slice(0, 2);
  const remainingCount = uniqueOrgs.length - 2;
  
  const summaryText = `${previousRoles.length} previous ${previousRoles.length === 1 ? 'role' : 'roles'} including ${topOrgs.join(', ')}${remainingCount > 0 ? ` and ${remainingCount} ${remainingCount === 1 ? 'other' : 'others'}` : ''}`;

  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="body2" color="text.secondary">
          {summaryText}
        </Typography>
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{ mt: 1 }}
        >
          {isExpanded ? 'Show Less' : 'Show All'}
        </Button>
      </Box>

      <Collapse in={isExpanded}>
        <List disablePadding>
          {previousRoles.map((role, index) => (
            <React.Fragment key={role.id || index}>
              {index > 0 && <Divider sx={{ my: 1 }} />}
              <ListItem disableGutters sx={{ display: 'block' }}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="subtitle2">
                      {role.title}
                    </Typography>
                  }
                  secondary={
                    <Stack spacing={0.5}>
                      <Typography variant="body2" color="text.secondary">
                        {role.organisation}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {formatDate(role.start_date)} - {formatDate(role.end_date)}
                      </Typography>
                      {role.commitment && (
                        <Typography variant="body2" color="text.secondary">
                          {role.commitment}
                        </Typography>
                      )}
                    </Stack>
                  }
                />
              </ListItem>

            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </Stack>
  );
};

export default PreviousExperience;