// src/components/navigation/AccountMenu.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Logout as LogoutIcon,
  Refresh as RefreshIcon,
  Stars as StarsIcon,
} from '@mui/icons-material';
import { useAuth0 } from "@auth0/auth0-react";
import useAPIWithAuth from '../hooks/useAPIWithAuth';

const MENU_ITEMS = [
  {
    label: 'Revisit onboarding',
    icon: RefreshIcon,
    action: 'onboarding',
    path: '/onboarding',
  },
  {
    label: 'Revisit new onboarding',
    icon: RefreshIcon,
    action: 'onboarding-new',
    path: '/onboarding-new',
  },
  {
    label: 'Revisit aspirations',
    icon: StarsIcon,
    action: 'aspirations',
    path: '/aspirations',
  },
  {
    label: 'Clear assistant',
    icon: StarsIcon,
    action: 'assistant',
    path: '/ai',
  },
];

const AccountMenu = ({ variant = 'sidebar' }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout, user } = useAuth0();
  const api = useAPIWithAuth();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = async (action, path) => {
    handleClose();
    if (action) {
      await api.post(`/chat/${action}`);
    }
    if (path) {
      navigate(path);
    }
  };

  const handleLogout = () => {
    handleClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const avatarButton = (
    <ListItemButton 
      onClick={handleMenu}
      sx={variant === 'topnav' ? {
        minWidth: 'auto',
        borderRadius: 1,
        px: 1,
      } : undefined}
    >
      <Avatar
        src={user?.picture}
        alt={user?.name || 'User avatar'}
        sx={{ 
          mr: variant === 'topnav' ? 0 : 2,
          width: variant === 'topnav' ? 32 : 40,
          height: variant === 'topnav' ? 32 : 40,
        }}
        imgProps={{ referrerPolicy: "no-referrer" }}
      />
      {variant !== 'topnav' && (
        <Box sx={{ minWidth: 0 }}>
          <Typography variant="body2" noWrap>
            {user?.name || 'User'}
          </Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {user?.email?.toLowerCase()}
          </Typography>
        </Box>
      )}
    </ListItemButton>
  );

  return (
    <Box sx={variant === 'sidebar' ? { mt: 'auto' } : undefined}>
      {avatarButton}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: 220,
          },
        }}
      >
        <Box sx={{ px: 2, py: 1 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name || 'User'}
          </Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {user?.email?.toLowerCase()}
          </Typography>
        </Box>
        <Divider />
        {MENU_ITEMS.map(({ label, icon: Icon, action, path }) => (
          <MenuItem 
            key={label} 
            onClick={() => handleMenuAction(action, path)}
          >
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AccountMenu;