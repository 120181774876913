import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, ListItemText, DialogActions, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import DescriptionIcon from '@mui/icons-material/Description';
import EncryptionIcon from '@mui/icons-material/EnhancedEncryption';

const DataControlsModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <DialogTitle>Data Access Controls</DialogTitle>
    <DialogContent>
      <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>
        Your information is completely locked by default.
      </Typography>
      <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>
        To grant access, choose from the following options:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon><SecurityIcon /></ListItemIcon>
          <ListItemText 
            primary="Trusted Network (Recommended)" 
            secondary="Share with our vetted firms, all of whom have signed NDAs, to maximise exposure to relevant opportunities." 
          />
        </ListItem>
        <ListItem>
          <ListItemIcon><BusinessIcon /></ListItemIcon>
          <ListItemText 
            primary="Specific Firm Access" 
            secondary="Allow access only to specific firms or categories (e.g., SHREK, in-house teams, board boutiques)." 
          />
        </ListItem>
        <ListItem>
          <ListItemIcon><DescriptionIcon /></ListItemIcon>
          <ListItemText 
            primary="Selective Data Sharing" 
            secondary="Specify which data fields to share." 
          />
        </ListItem>
        <ListItem>
          <ListItemIcon><LockIcon /></ListItemIcon>
          <ListItemText 
            primary="Approval-Based Access" 
            secondary="Require your explicit approval for each access request." 
          />
        </ListItem>
        <ListItem>
          <ListItemIcon><LockIcon /></ListItemIcon>
          <ListItemText 
            primary="Maintain Full Lockdown" 
            secondary="Keep all access closed, visible only to you." 
          />
        </ListItem>
      </List>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        To set your sharing preferences now, click <a href="/">here</a>. Or you can do it back in the chat interface once you've built your basic profile.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

const BannerContent = ({ status, title, description, icon: Icon }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Icon sx={{ mr: 2, color: "black" }} />
    <Box>
      <Typography variant="subtitle1" color="text.primary">
        Data Access Controls: <Box component="span" fontWeight="bold">{title}</Box>
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
        <EncryptionIcon sx={{ fontSize: 14, mr: 0.5 }} />
        All data is securely encrypted
      </Typography>
    </Box>
  </Box>
);

const DataControlsBanner = ({ dataControl }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const getBannerProps = () => {
    if (!dataControl) {
      return {
        status: 'private',
        title: 'Locked',
        description: 'This profile is currently locked and not visible to any third parties',
        icon: LockIcon,
        backgroundColor: 'warning.light'
      };
    }

    const isFullyOpen = dataControl.sharing_status === 'standard';
    return {
      status: isFullyOpen ? 'restricted' : 'locked',
      title: isFullyOpen ? 'Restricted' : 'Locked',
      description: isFullyOpen
        ? "Profile is visible to verified search firms and hiring organizations that have signed an NDA"
        : dataControl.firm_access?.allowed_types?.length > 0
          ? `Visible only to: ${dataControl.firm_access.allowed_types.join(', ')}`
          : "Data is completely private - no third parties can see any of your information.",
      icon: isFullyOpen ? LockOpenIcon : LockIcon,
      backgroundColor: isFullyOpen ? 'grey.light' : 'warning.light'
    };
  };

  const bannerProps = getBannerProps();

  return (
    <>
      <Paper
        elevation={0}
        sx={{
            p: 2,
            mb: 2,
            backgroundColor: bannerProps.backgroundColor,
            display: 'flex',
            flexDirection: 'column', // Stack items vertically
            alignItems: 'flex-start', // Align items to the left
        }}
        >
        <BannerContent {...bannerProps} />
        <Box sx={{ mt: 1}}> 
            <IconButton sx={{ color: 'black', textDecoration: 'underline' }} size="small" onClick={() => setModalOpen(true)}>
            <InfoIcon sx={{mr:1}}/>
            Learn more
            </IconButton>
        </Box>
        </Paper>
      <DataControlsModal 
        open={modalOpen} 
        onClose={() => setModalOpen(false)} 
      />
    </>
  );
};

export default DataControlsBanner;