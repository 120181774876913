import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemText,
  Box,
  Chip
} from '@mui/material';
import { Shield as SecurityIcon } from 'lucide-react';
import EmptyState from './EmptyState';
import { hasNonNullValues } from '../utils/hasNonNullValues';

const Section = ({ label, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

const RestrictedFieldsList = ({ fields }) => {
  if (!fields) return null;
  return (
    <List dense disablePadding>
      {Object.entries(fields).map(([fieldName, details]) => (
        <ListItem key={fieldName} disableGutters>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2">
                  {fieldName}
                </Typography>
                <Chip 
                  label={details.restriction_type} 
                  size="small"
                  variant="outlined"
                  sx={{ height: 20 }}
                />
              </Box>
            }
            secondary={details.notes && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {details.notes}
              </Typography>
            )}
          />
        </ListItem>
      ))}
    </List>
  );
};

const AllowedTypesList = ({ types }) => {
  if (!types?.length) return null;
  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {types.map((type) => (
        <Chip 
          key={type} 
          label={type} 
          size="small"
          variant="outlined"
          sx={{ height: 20 }}
        />
      ))}
    </Box>
  );
};

const DataControl = ({ details }) => {
  if (!hasNonNullValues(details)) {
    return (
      <EmptyState 
        icon={SecurityIcon}
        title="No data control settings configured"
        description="Set your data sharing preferences to control who can see your information"
      />
    );
  }
  const { 
    sharing_status,
    restricted_fields,
    firm_access,
    special_handling,
    last_updated,
    notes
  } = details;

  return (
    <Stack spacing={2}>
      {/* Sharing Status */}
      {sharing_status && (
        <Box>
          <Typography variant="body2">
            {sharing_status}
          </Typography>
          {last_updated && (
            <Typography variant="body2" color="text.secondary">
              Last updated: {last_updated}
            </Typography>
          )}
        </Box>
      )}

      {/* Restricted Fields */}
      {restricted_fields && (
        <Section label="Field Restrictions">
          <RestrictedFieldsList fields={restricted_fields} />
        </Section>
      )}

      {/* Firm Access */}
      {firm_access && (
        <Section label="Firm Access">
          {firm_access.allowed_types && (
            <AllowedTypesList types={firm_access.allowed_types} />
          )}
          {firm_access.specific_restrictions && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {firm_access.specific_restrictions}
            </Typography>
          )}
        </Section>
      )}

      {/* Special Handling */}
      {special_handling && (
        <Section label="Special Handling">
          {special_handling.concerns && (
            <Typography variant="body2">
              {special_handling.concerns}
            </Typography>
          )}
          {special_handling.status && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              Status: {special_handling.status}
            </Typography>
          )}
        </Section>
      )}

      {/* Additional Notes */}
      {notes && (
        <Typography variant="body2" color="text.secondary">
          {notes}
        </Typography>
      )}
    </Stack>
  );
};

export default DataControl;