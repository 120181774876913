import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useAnalytics } from './analytics/useAnalytics';
import { usePageTracking } from './analytics/tracking';
import { ProtectedRoute } from './components/routing/ProtectedRoute';
import { PublicRoute } from './components/routing/PublicRoute';
import { InviteRoute } from './components/routing/InviteRoute';
import theme from './theme';
import PageLayout from './components/PageLayout';
import { NAV_ROUTES_ARRAY, AUTH_ROUTES } from './routes';

const MainContent = () => {
  usePageTracking();
  useAnalytics();
  return (
    <Routes>
      {/* Public-only routes - for non-authenticated users */}
      <Route
        path={AUTH_ROUTES.HOME.path}
        element={
          <PublicRoute>
            <AUTH_ROUTES.HOME.Element />
          </PublicRoute>
        }
      />
      <Route
        path={AUTH_ROUTES.INTRODUCTION.path}
        element={
          <InviteRoute>
            <AUTH_ROUTES.INTRODUCTION.Element />
          </InviteRoute>
        }
      />
      {/* Auth flow routes */}
      <Route
        path={AUTH_ROUTES.ONBOARDING.path}
        element={
          <ProtectedRoute>
            <AUTH_ROUTES.ONBOARDING.Element {...AUTH_ROUTES.ONBOARDING.props} />
          </ProtectedRoute>
        }
      />
      <Route
        path={AUTH_ROUTES.ONBOARDING_NEW.path}
        element={
          <ProtectedRoute>
            <AUTH_ROUTES.ONBOARDING_NEW.Element {...AUTH_ROUTES.ONBOARDING_NEW.props} />
          </ProtectedRoute>
        }
      />
      <Route
        path={AUTH_ROUTES.ASPIRATIONS.path}
        element={
          <ProtectedRoute>
            <AUTH_ROUTES.ASPIRATIONS.Element {...AUTH_ROUTES.ASPIRATIONS.props} />
          </ProtectedRoute>
        }
      />
      <Route
        path={AUTH_ROUTES.SAMPLE_PROFILE.path}
        element={
          <PublicRoute>
            <AUTH_ROUTES.SAMPLE_PROFILE.Element />
          </PublicRoute>
        }
      />
      <Route
        path={AUTH_ROUTES.WAITLIST.path}
        element={
          <ProtectedRoute requiresAccount={false}>
            <AUTH_ROUTES.WAITLIST.Element />
          </ProtectedRoute>
        }
      />
      {/* Main navigation routes - all protected and using PageLayout */}
      {NAV_ROUTES_ARRAY.map(route => (
        <Route
          key={route.id}
          path={route.path}
          element={
            <ProtectedRoute>
              <PageLayout>
                <route.Component />
              </PageLayout>
            </ProtectedRoute>
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="nexco.uk.auth0.com"
    clientId="2NF2m4jVgqxQmXBfpgSUxCbyvvtBLlZI" 
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://api.nextco.ai",
      scope: "openid profile email offline_access"
    }}
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainContent />
      </ThemeProvider>
    </BrowserRouter>
  </Auth0Provider>
);