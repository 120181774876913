import React from 'react';
import { 
  Typography,
  Box,
  Chip,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { 
  Home as HomeIcon,
  Business as BusinessIcon,
  Block as BlockIcon,
  TravelExplore as TravelIcon,
} from '@mui/icons-material';
import { MapPin as LocationIcon } from 'lucide-react';
import EmptyState from './EmptyState';
import { hasNonNullValues } from '../utils/hasNonNullValues';

const Location = ({ location }) => {
  if (!location) return null;
  
  const locationParts = [
    location.city,
    location.region,
    location.country
  ].filter(Boolean);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <HomeIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
      <Typography variant="body2">
        {locationParts.join(', ')}
        {location.details && (
          <Typography variant="body2" color="text.secondary" component="span" sx={{ ml: 1 }}>
            ({location.details})
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

const Section = ({ title, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

const ArrangementChips = ({ arrangements, color = "default", icon }) => {
  if (!arrangements?.length) return null;

  const getLabel = (arrangement) => {
    const labels = {
      'fully_remote': 'Remote',
      'hybrid': 'Hybrid',
      'office_based': 'Office-based'
    };
    return labels[arrangement] || arrangement;
  };

  return (
    <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
      {arrangements.map((arrangement, index) => (
        <Chip
          key={index}
          label={getLabel(arrangement)}
          size="small"
          color={color}
          variant="outlined"
          icon={icon && React.createElement(icon, { sx: { fontSize: 16 } })}
          sx={{ height: 24 }}
        />
      ))}
    </Box>
  );
};

const TravelRestrictions = ({ domestic, international }) => {
  if (!domestic && !international) return null;

  return (
    <List dense disablePadding>
      {domestic && (
        <ListItem disablePadding>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <TravelIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">
                Domestic Travel: {domestic}
              </Typography>
            }
          />
        </ListItem>
      )}
      {international && (
        <ListItem disablePadding sx={{ mt: 0.5 }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <TravelIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">
                International Travel: {international}
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
};

const LocationPreferences = ({ preferences }) => {
  if (!hasNonNullValues(preferences)) {
    return (
      <EmptyState 
        icon={LocationIcon}
        title="No location preferences set"
        description="Share your preferences about work location, travel requirements, and any location-based restrictions"
      />
    );
  }

  const { preferences: prefs, red_lines: redLines, notes } = preferences;

  return (
    <Stack spacing={2.5}>
      {/* Current Home Locations */}
      {prefs?.home_locations?.length > 0 && (
        <Section title="Current Location">
          <Stack spacing={1}>
            {prefs.home_locations.map((location, index) => (
              <Location key={index} location={location} />
            ))}
          </Stack>
        </Section>
      )}

      {/* Working Arrangements */}
      {prefs?.preferred_arrangements?.length > 0 && (
        <Section title="Preferred Working Arrangements">
          <ArrangementChips 
            arrangements={prefs.preferred_arrangements} 
            color="primary"
            icon={BusinessIcon}
          />
        </Section>
      )}

      {/* Preferred Locations */}
      {prefs?.preferred_locations?.length > 0 && (
        <Section title="Would Consider">
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {prefs.preferred_locations.map((location, index) => (
              <Chip
                key={index}
                label={location}
                size="small"
                variant="outlined"
                icon={<LocationIcon size={16} />}
                sx={{ height: 24 }}
              />
            ))}
          </Box>
        </Section>
      )}

      {/* Commute & Relocation */}
      {(prefs?.max_commute_willing || prefs?.relocation_considerations) && (
        <Box>
          {prefs.max_commute_willing && (
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Maximum commute: {prefs.max_commute_willing}
            </Typography>
          )}
          {prefs.relocation_considerations && (
            <Typography variant="body2" color="text.secondary">
              {prefs.relocation_considerations}
            </Typography>
          )}
        </Box>
      )}

      {/* Restrictions/Red Lines */}
      {redLines && (
        <>
          {/* Location Exclusions */}
          {redLines.excluded_locations?.length > 0 && (
            <Section title="Will Not Consider">
              <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                {redLines.excluded_locations.map((location, index) => (
                  <Chip
                    key={index}
                    label={location}
                    size="small"
                    color="error"
                    variant="outlined"
                    icon={<BlockIcon sx={{ fontSize: 16 }} />}
                    sx={{ height: 24 }}
                  />
                ))}
              </Box>
            </Section>
          )}

          {/* Excluded Arrangements */}
          {redLines.excluded_arrangements?.length > 0 && (
            <Section title="Arrangement Restrictions">
              <ArrangementChips 
                arrangements={redLines.excluded_arrangements} 
                color="error"
                icon={BlockIcon}
              />
            </Section>
          )}

          {/* Travel Restrictions */}
          {(redLines.max_travel_domestic || redLines.max_travel_international) && (
            <Section title="Travel Restrictions">
              <TravelRestrictions
                domestic={redLines.max_travel_domestic}
                international={redLines.max_travel_international}
              />
            </Section>
          )}

          {/* Other Restrictions */}
          {redLines.other_restrictions && (
            <Typography variant="body2" color="text.secondary">
              {redLines.other_restrictions}
            </Typography>
          )}
        </>
      )}

      {/* Additional Notes */}
      {notes && (
        <Typography variant="body2" color="text.secondary">
          {notes}
        </Typography>
      )}
    </Stack>
  );
};

export default LocationPreferences;