export const hasNonNullValues = (data) => {
  if (!data) return false;
  
  if (Array.isArray(data)) {
    return data.length > 0 && data.some(item => hasNonNullValues(item));
  }
  
  if (typeof data === 'object') {
    return Object.values(data).some(value => {
      if (value === null) return false;
      if (Array.isArray(value)) return hasNonNullValues(value);
      if (typeof value === 'object') return hasNonNullValues(value);
      return value != null;
    });
  }
  
  return data != null;
};