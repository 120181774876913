import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import NavigationMenu from './NavigationMenu';

const PageLayout = ({ children, maxWidth = 800 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Desktop Navigation - now full width */}
      {!isMobile && (
        <Box sx={{ width: '100vw', position: 'sticky', top: 0, zIndex: 1100 }}>
          <NavigationMenu />
        </Box>
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: isMobile ? '64px' : 0,
        }}
      >
        <Container 
          maxWidth="lg" 
          sx={{ 
            py: 3,
            '& > *': {
              maxWidth: `${maxWidth}px`,
              mx: 'auto'
            }
          }}
        >
          {children}
        </Container>
      </Box>

      {/* Mobile Navigation */}
      {isMobile && <NavigationMenu />}
    </Box>
  );
};

export default PageLayout;