import React from 'react';
import { Typography, Box } from '@mui/material';

const EmptyState = ({ icon: Icon, title, description }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      py: 4,
      px: 2,
      textAlign: 'center',
      color: 'text.secondary',
      bgcolor: 'background.paper',
      borderRadius: 1
    }}
  >
    <Icon size={32} style={{ marginBottom: '16px', opacity: 0.5 }} />
    <Typography variant="body1" gutterBottom>{title}</Typography>
    <Typography variant="body2">{description}</Typography>
  </Box>
);

export default EmptyState;