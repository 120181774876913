// components/profile/sections/ProfessionalSatisfaction.jsx
import React from 'react';
import { 
  Typography,
  Rating,
  Box
} from '@mui/material';

const ProfessionalSatisfaction = ({ satisfaction }) => {
  if (!satisfaction) return null;

  // Handle potential typo in 'openness_to_opportuinities'
  const opennessToOpportunities = satisfaction.openness_to_opportunities ?? satisfaction.openness_to_opportuinities;

  // Function to interpret openness score
  const getOpennessMessage = (score) => {
    if (score <= 3) {
      return 'Not open to new opportunities';
    } else if (score <= 7) {
      return 'May consider new opportunities';
    } else {
      return 'Actively looking for new opportunities';
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Rating 
          value={satisfaction.satisfaction_score || 0}
          max={10}
          readOnly
          size="small"
          sx={{ mb: 1 }}
        />
        <Typography variant="body2">
          {satisfaction.satisfaction_overview}
        </Typography>
      </Box>

      {satisfaction.growth && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Growth Opportunities
          </Typography>
          <Typography variant="body2">
            {satisfaction.growth}
          </Typography>
        </Box>
      )}

      {satisfaction.support && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Support and Dynamics
          </Typography>
          <Typography variant="body2">
            {satisfaction.support}
          </Typography>
        </Box>
      )}

      {satisfaction.recognition && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Recognition
          </Typography>
          <Typography variant="body2">
            {satisfaction.recognition}
          </Typography>
        </Box>
      )}

      {satisfaction.balance && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Work-Life Balance
          </Typography>
          <Typography variant="body2">
            {satisfaction.balance}
          </Typography>
        </Box>
      )}

      {satisfaction.challenges && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Challenges
          </Typography>
          <Typography variant="body2">
            {satisfaction.challenges}
          </Typography>
        </Box>
      )}

      {opennessToOpportunities !== null && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Openness to Opportunities
          </Typography>
          <Typography variant="body2">
            {getOpennessMessage(opennessToOpportunities)}
          </Typography>
        </Box>
      )}

      {satisfaction.compelling_factors && satisfaction.compelling_factors.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
            Compelling Factors
          </Typography>
          <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
            {satisfaction.compelling_factors.map((factor, index) => (
              <li key={index}>
                <Typography variant="body2">{factor}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default ProfessionalSatisfaction;
