import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import io from 'socket.io-client';

const SOCKET_SERVER = process.env.REACT_APP_SOCKET_SERVER;

export const useSocketChat = (chatType, { requireAuthentication = false, inviteCode = null } = {}) => {
  
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [profile, setProfile] = useState(null);
  const [progress, setProgress] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  // Initialize socket connection
  useEffect(() => {
    const setupSocket = async () => {
      const socketInstance = io(`${SOCKET_SERVER}/${chatType}`, {
        auth: async (cb) => {
          try {
            if (requireAuthentication) {
              const token = await getAccessTokenSilently();
              cb({ type: 'jwt', token });
            } else if (inviteCode) {
              cb({ type: 'invite', code: inviteCode });
            } else {
              cb({});
            }
          } catch (error) {
            console.error('Authentication failed:', error);
            cb(new Error('Authentication failed'));
          }
        },
        path: '/socket.io',
        transports: ['websocket']
      });
  
      setSocket(socketInstance);
  
      return () => {
        socketInstance.disconnect();
      };
    };

    setupSocket();
  }, [chatType, requireAuthentication, inviteCode, getAccessTokenSilently]);

  // Set up event listeners
  useEffect(() => {
    if (!socket) return;

    socket.on('message', (data) => {
      if (Array.isArray(data)) {
        setMessages(data);
      } else if (data.role) {
        setMessages(prevMessages => {
          const messageIndex = prevMessages.findIndex(msg => msg.id === data.id);
          if (messageIndex !== -1) {
            const newMessages = [...prevMessages];
            newMessages[messageIndex] = data;
            return newMessages;
          }
          return [...prevMessages, data];
        });
      }
    });

    socket.on('profile', (profile) => {
      setProfile(profile);
    });

    socket.on('progress', (progressData) => {
      setProgress(progressData);
    });

    return () => {
      socket.off('message');
      socket.off('profile update');
      socket.off('progress');
    };
  }, [socket]);

  const sendMessage = useCallback(async (content) => {
    if (!socket) return;
    
    // Add message optimistically
    setMessages(prev => [...prev, {
      role: 'user',
      content,
      id: Date.now(), // Simple ID generation
    }]);
    
    // Send to server
    socket.emit('message', { content });
  }, [socket]);

  return {
    isConnected: !!socket,
    messages,
    profile,
    progress,
    sendMessage
  };
};