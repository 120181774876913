import React from 'react';
import { Box } from '@mui/material';
import Profile from '../components/profile/Profile';

const SharedProfile = () => {
  return (
    <Box>
        <Profile/>
    </Box>
  );
};

export default SharedProfile;