import React from 'react';
import { 
  Typography,
  Box,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { 
  Building2 as OrganizationIcon,
  Scale as SizeIcon,
  Briefcase as SectorIcon,
  Building as OwnershipIcon
} from 'lucide-react';
import EmptyState from './EmptyState';
import { hasNonNullValues } from '../utils/hasNonNullValues';

const PreferenceSection = ({ icon: Icon, title, content }) => {
  if (!content) return null;
  
  return (
    <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
      <ListItemIcon sx={{ minWidth: 36, mt: 0.5 }}>
        <Icon size={18} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {title}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="text.primary">
            {content}
          </Typography>
        }
      />
    </ListItem>
  );
};

const OrganisationPreferences = ({ preferences }) => {
  if (!hasNonNullValues(preferences)) {
    return (
      <EmptyState 
        icon={OrganizationIcon}
        title="No organization preferences set"
        description="Share your preferences about company type, size, industry, and any restrictions to help find the right opportunities"
      />
    );
  }

  const { 
    sector_preferences,
    size_preferences,
    ownership_preferences,
    absolute_exclusions,
    notes
  } = preferences;

  const hasPreferences = sector_preferences || size_preferences || ownership_preferences;

  return (
    <Stack spacing={2}>
      {/* Preferences Sections */}
      {hasPreferences && (
        <List disablePadding>
          <PreferenceSection
            icon={SectorIcon}
            title="Industry & Sector"
            content={sector_preferences}
          />
          <PreferenceSection
            icon={SizeIcon}
            title="Company Size"
            content={size_preferences}
          />
          <PreferenceSection
            icon={OwnershipIcon}
            title="Ownership & Funding"
            content={ownership_preferences}
          />
        </List>
      )}

      {/* Absolute Exclusions */}
      {absolute_exclusions?.length > 0 && (
        <>
          {hasPreferences && <Divider sx={{ my: 2 }} />}
          <Box>
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ mb: 1 }}
            >
              Will not consider:
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
              {absolute_exclusions.map((exclusion, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: 'error.main',
                    color: 'error.main',
                    borderRadius: '16px',
                    padding: '3px 8px',
                  }}
                >
                  <BlockIcon sx={{ fontSize: 16, mr: 0.5 }} />
                  {exclusion}
                </Typography>
              ))}
            </Box>
          </Box>
        </>
      )}

      {/* Additional Notes */}
      {notes && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography 
            variant="body2" 
            color="text.secondary"
          >
            {notes}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default OrganisationPreferences;