import React from 'react';
import { Box } from '@mui/material';
import ChatContainer from '../components/chat/ChatContainer';

const AIAssistant = () => {
  return (
    <Box>
        <ChatContainer
            chatType="assistant"
            requireAuthentication={true}
        />
    </Box>
  );
};

export default AIAssistant;