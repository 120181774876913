import {
  UserCheck as ProfileIcon,
  LockKeyhole as VaultIcon,
  ListTodo as NextStepsIcon,
  Bot as AIIcon,
} from 'lucide-react';
import LogoIcon from './components/LogoIcon';
import LoggedOutHome from './components/LoggedOutHome';
import Introduction from './components/introduction/Introduction';
import ChatWithProgress from './components/chat/ChatWithProgress';
import ExecutiveProfile from './components/executive_profile/ExecutiveProfile';
import Waitlist from './components/Waitlist';
import Dashboard from './pages/Dashboard';
import SharedProfile from './pages/SharedProfile';
import PrivateVault from './pages/PrivateVault';
import Achievements from './pages/Achievements';
import AIAssistant from './pages/AIAssistant';

export const AUTH_ROUTES = {
  HOME: {
    path: '/',
    Element: LoggedOutHome,
    public: true
  },
  INTRODUCTION: {
    path: '/introduction',
    Element: Introduction,
    requiresInvite: true
  },
  ONBOARDING: {
    path: '/onboarding',
    Element: ChatWithProgress,
    props: { conversationType: 'onboarding' }
  },
  ONBOARDING_NEW: {
    path: '/onboarding-new',
    Element: ChatWithProgress,
    props: { conversationType: 'onboarding-new' }
  },
  ASPIRATIONS: {
    path: '/aspirations',
    Element: ChatWithProgress,
    props: { conversationType: 'aspirations' }
  },
  SAMPLE_PROFILE: {
    path: '/sample-profile',
    Element: ExecutiveProfile,
    public: true
  },
  WAITLIST: {
    path: '/waitlist',
    Element: Waitlist,
    requiresAccount: false
  }
};

export const NAV_ROUTES = {
  DASHBOARD: {
    id: 'dashboard',
    path: '/dashboard',
    label: 'Nexco.ai',
    icon: LogoIcon,
    description: 'Overview and insights',
    Component: Dashboard
  },
  SHARED_PROFILE: {
    id: 'shared-profile',
    path: '/shared-profile',
    label: 'Shared Profile',
    shortLabel: 'Profile',
    icon: ProfileIcon,
    color: '#00C2A8',
    headline: 'Unlock Job Opportunities',
    shortDescription: 'Get noticed by headhunters',
    description: 'Your shared professional profile, selectively shared with headhunters and internal search teams at the worlds largest companies',
    cta: 'Go to profile',
    Component: SharedProfile
  },
  PRIVATE_VAULT: {
    id: 'private-vault',
    path: '/private-vault',
    label: 'Private Vault',
    shortLabel: 'Vault',
    icon: VaultIcon,
    color: '#6A4C93',
    headline: 'Organise Career Records',
    shortDescription: 'Your documents, always at your fingertips',
    description: 'Store and access your career essentials—CVs, references, contracts, and more—in a secure and private vault.',
    cta: 'Populate now',
    Component: PrivateVault
  },
  ACHIEVEMENTS: {
    id: 'achievements',
    path: '/achievements',
    label: 'Record achievements',
    shortLabel: 'Achievements',
    icon: NextStepsIcon,
    color: '#FF7600',
    headline: 'Record Your Impact',
    shortDescription: 'Turn achievements into leverage',
    description: 'Create a portfolio of accomplishments to unlock promotions, opportunities, and career clarity.',
    cta: 'Record now',
    Component: Achievements
  },
  NEXCO_AI: {
    id: 'assistant',
    path: '/ai',
    label: 'AI assistant',
    shortLabel: 'Assist',
    icon: AIIcon,
    color: '#FFC300',
    headline: 'Level-Up with AI',
    shortDescription: 'Your digital mentor',
    description: 'Use AI for tailored support with writing CVs, collating annual review packs and more.',
    cta: 'Chat now',
    Component: AIAssistant
  }
};

export const NAV_ROUTES_ARRAY = Object.values(NAV_ROUTES);
export const MOBILE_NAV_ROUTES_ARRAY = [NAV_ROUTES_ARRAY[3],NAV_ROUTES_ARRAY[1],NAV_ROUTES_ARRAY[0],NAV_ROUTES_ARRAY[2],NAV_ROUTES_ARRAY[4]]
export const AUTH_ROUTES_ARRAY = Object.values(AUTH_ROUTES);