import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Divider
} from '@mui/material';
import {
  Network as OrgIcon,
  BarChart as MetricsIcon,
  User as DirectReportIcon,
  Users as IndirectReportIcon,
  ArrowUpCircle as ReportsToIcon,
  Target as MetricIcon,
  Info as ContextIcon
} from 'lucide-react';

const Section = ({ title, icon: Icon, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1.5 }}>
        <Icon size={20} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
        <Typography variant="subtitle2">
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

const ReportingStructure = ({ reporting }) => {
  if (!reporting) return null;

  return (
    <Section title="Reporting Structure" icon={OrgIcon}>
      {/* Position Context */}
      {reporting.position_context && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          {reporting.position_context}
        </Typography>
      )}

      {/* Reports To */}
      {(reporting.reporting_line || reporting.reporting_line_name) && (
        <ListItem disableGutters sx={{ mb: 1 }}>
          <ListItemIcon sx={{ minWidth: 32 }}>
            <ReportsToIcon size={16} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">
                Reports to: {reporting.reporting_line}
                {reporting.reporting_line_name && ` (${reporting.reporting_line_name})`}
              </Typography>
            }
          />
        </ListItem>
      )}

      {/* Direct Reports */}
      {reporting.direct_reports_details?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <DirectReportIcon size={16} />
            <Typography variant="body2" color="text.secondary">
              Direct Reports ({reporting.direct_reports_details.length})
            </Typography>
          </Box>
          <List dense disablePadding>
            {reporting.direct_reports_details.map((report, index) => (
              <ListItem key={index} disableGutters sx={{ pl: 4 }}>
                <ListItemText
                  primary={<Typography variant="body2">{report}</Typography>}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {/* Indirect Reports */}
      {(reporting.total_number_of_indirect_reports || reporting.indirect_report_breakdown) && (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
            <IndirectReportIcon size={16} />
            <Typography variant="body2" color="text.secondary">
              Indirect Reports
              {reporting.total_number_of_indirect_reports && 
                ` (${reporting.total_number_of_indirect_reports})`}
            </Typography>
          </Box>
          {reporting.indirect_report_breakdown && (
            <Typography variant="body2" sx={{ pl: 4 }}>
              {reporting.indirect_report_breakdown}
            </Typography>
          )}
        </Box>
      )}
    </Section>
  );
};

const MetricsList = ({ metrics }) => {
  if (!metrics?.key_metrics?.length) return null;

  // Group metrics by type for better organization
  const groupedMetrics = metrics.key_metrics.reduce((acc, metric) => {
    if (!acc[metric.type]) {
      acc[metric.type] = [];
    }
    acc[metric.type].push(metric);
    return acc;
  }, {});

  return (
    <Section title="Key Metrics" icon={MetricsIcon}>
      {Object.entries(groupedMetrics).map(([type, typeMetrics]) => (
        <Box key={type} sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            {type}
          </Typography>
          <List dense disablePadding>
            {typeMetrics.map((metric, index) => (
              <ListItem key={index} disableGutters>
                <ListItemIcon sx={{ minWidth: 32 }}>
                  <MetricIcon size={16} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2">
                        {metric.value}
                      </Typography>
                    </Box>
                  }
                  secondary={metric.description}
                  secondaryTypographyProps={{ variant: 'body2' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
      
      {metrics.additional_context && (
        <Box sx={{ mt: 1 }}>
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <ContextIcon size={16} />
            </ListItemIcon>
            <ListItemText
              secondary={metrics.additional_context}
              secondaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        </Box>
      )}
    </Section>
  );
};

export const RoleScopeDetails = ({ scope }) => {
  if (!scope) return null;

  return (
    <Stack spacing={3} divider={<Divider />}>
      <ReportingStructure reporting={scope.reporting} />
      <MetricsList metrics={scope.metrics} />
      
      {scope.notes && (
        <Typography variant="body2" color="text.secondary">
          {scope.notes}
        </Typography>
      )}
    </Stack>
  );
};

export default RoleScopeDetails;