import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
  Divider
} from '@mui/material';
import {
  Building2 as OfficeIcon,
  Timer as CommuteIcon,
  Calendar as PatternIcon,
  Plane as InternationalIcon,
  Train as DomesticIcon,
  MapPin as LocationIcon
} from 'lucide-react';

const Section = ({ title, icon: Icon, children }) => {
  if (!children) return null;
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1.5 }}>
        <Icon size={20} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

const WorkingPatternChip = ({ pattern }) => {
  const patterns = {
    'fully_remote': { label: 'Remote', color: 'success' },
    'hybrid': { label: 'Hybrid', color: 'info' },
    'office_based': { label: 'Office-based', color: 'warning' }
  };

  const config = patterns[pattern] || { label: pattern, color: 'default' };

  return (
    <Chip
      label={config.label}
      color={config.color}
      size="small"
      variant="outlined"
      sx={{ height: 24 }}
    />
  );
};

const Location = ({ location }) => {
  if (!location) return null;

  const locationParts = [
    location.city,
    location.region,
    location.country
  ].filter(Boolean);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body2">
        {locationParts.join(', ')}
      </Typography>
      {location.details && (
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ fontStyle: 'italic' }}
        >
          ({location.details})
        </Typography>
      )}
    </Box>
  );
};

const CurrentArrangements = ({ current }) => {
  if (!current) return null;

  return (
    <Section title="Current Work Setup" icon={OfficeIcon}>
      <List dense disablePadding>
        {/* Working Pattern */}
        {current.working_pattern && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <PatternIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <WorkingPatternChip pattern={current.working_pattern} />
                  {current.pattern_details && (
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{ ml: 1 }}
                    >
                      {current.pattern_details}
                    </Typography>
                  )}
                </Box>
              }
            />
          </ListItem>
        )}

        {/* Office Location */}
        {current.office_location && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <LocationIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={<Location location={current.office_location} />}
            />
          </ListItem>
        )}

        {/* Commute */}
        {current.current_commute && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <CommuteIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={
                <Typography variant="body2">
                  {current.current_commute}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </Section>
  );
};

const TravelRequirements = ({ travel }) => {
  if (!travel?.domestic_frequency && !travel?.international_frequency) return null;

  return (
    <Section title="Travel Requirements" icon={InternationalIcon}>
      <List dense disablePadding>
        {travel.domestic_frequency && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <DomesticIcon size={16} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">
                  Domestic Travel: {travel.domestic_frequency}
                </Typography>
              }
            />
          </ListItem>
        )}

        {travel.international_frequency && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <InternationalIcon size={16} />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">
                  International Travel: {travel.international_frequency}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </Section>
  );
};

export const RoleArrangementsDetails = ({ details }) => {
  if (!details) return null;

  const { current, travel } = details;

  return (
    <Stack spacing={3} divider={<Divider />}>
      <CurrentArrangements current={current} />
      <TravelRequirements travel={travel} />
    </Stack>
  );
};

export default RoleArrangementsDetails;