// analytics/identify.js

import { clarity } from 'react-microsoft-clarity';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";

export const identifyUser = (user, hasAccount) => {
  if (process.env.NODE_ENV === 'development') return;

  const userType = hasAccount === "ready" ? "active" : "waitlist";
  const authProvider = user.sub.split('|')[0];

  Sentry.setUser({
    id: user.sub,
    email: user.email,
    username: user.name || user.nickname,
    type: userType
  });

  clarity.identify({
    userId: user.sub,
    email: user.email,
    name: user.name || user.nickname || 'Unknown',
    hasAccount: hasAccount === "ready" ? "yes" : "no",
    userType: userType,
    authProvider: authProvider
  });

  const identify = new amplitude.Identify();
  identify.set('email', user.email);
  identify.set('name', user.name || user.nickname);
  identify.set('hasAccount', hasAccount === "ready" ? "yes" : "no");
  identify.set('userType', userType);
  identify.set('authProvider', authProvider);
  identify.set('signupDate', user.updated_at);

  amplitude.setUserId(user.sub);
  amplitude.identify(identify);
};