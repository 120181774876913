import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Progress from './Progress';

const useDelayedError = (isConnected, error, delay = 2000) => {
  const [showError, setShowError] = useState(false);
  
  useEffect(() => {
    let timeoutId;
    
    if (!isConnected && error) {
      timeoutId = setTimeout(() => setShowError(true), delay);
    } else {
      setShowError(false);
    }

    return () => clearTimeout(timeoutId);
  }, [isConnected, error, delay]);

  return showError;
};

const ConnectionStatus = ({ isConnected, error, showError }) => {
  // Show nothing if connected
  if (isConnected) return null;
  
  // Show connecting message if no error or error not yet shown
  if (!error || !showError) {
    return (
      <div className="text-blue-600 bg-blue-50 p-3 rounded">
        Connecting...
      </div>
    );
  }
  
  // Show error if disconnected with error and delay elapsed
  return (
    <div className="text-red-600 bg-red-50 p-3 rounded">
      {error}
    </div>
  );
};

const ProgressTracker = ({ socketData }) => {
  const [summary, setSummary] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Use the existing socket hook but add progress handling
  const isConnected = socketData.isConnected;
  const progress = socketData.progress;
  const socketError = socketData.error;
  const sendMessage = socketData.sendMessage;

  // Update summary when progress changes
  useEffect(() => {
    if (progress) {
      const progressValues = Object.values(progress);
      
      // Check that we have at least one value and all are completed
      if (progressValues.length > 0 && progressValues.every(value => value === "completed")) {
        navigate('/shared-profile');
        return;
      }
      
      // Otherwise update the summary
      setSummary(Object.entries(progress));
    }
  }, [progress, navigate]);

  useEffect(() => {
    if (socketError) {
      setError('Connection error. Please try again later.');
    } else {
      setError(null);
    }
  }, [socketError]);

  // Handle delayed error display
  const showError = useDelayedError(isConnected, error);

  const handleStageClick = (stage) => {
    if (!isConnected) return;
    
    // Send stage click through socket
    sendMessage({
      action: 'stage_click',
      stage
    });
  };

  return (
    <div className="space-y-4">
      <ConnectionStatus 
        isConnected={isConnected} 
        error={error}
        showError={showError}
      />
      
      <Progress 
        summary={summary}
        onStageClick={handleStageClick}
        disabled={!isConnected}
      />
    </div>
  );
};

export default ProgressTracker;