import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../sidebar/Sidebar';
import { useSocketChat } from '../../hooks/useSocketChat';
import ChatAndArtifactsContainer from './ChatAndArtifactsContainer';
import { ChatContainer } from './ChatContainer';
import ProfileAccordion from '../profile/ProfileAccordion';
import ProgressTracker from './ProgressTracker';

const ChatWithProgress = ({ conversationType }) => {  // Properly destructure the prop
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;
  const socketData = useSocketChat("onboarding-new", {requireAuthentication: true});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const ProgressTrackerWithSocket = (props) => (
    <ProgressTracker
      {...props}
      socketData={socketData}
    />
  );
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        content={ProgressTrackerWithSocket}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <ChatAndArtifactsContainer
          ChatComponent={(props) => (
            <ChatContainer
              socketData={socketData}
              requireAuthentication={true}
              {...props}
            />
          )}
          ProfileComponent={(props) => (
            <ProfileAccordion
              socketData={socketData}
              {...props}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ChatWithProgress;