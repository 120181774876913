import React from 'react';
import { 
  Typography, 
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Chip,
  Divider
} from '@mui/material';
import {
  DollarSign as MoneyIcon,
  Percent as BonusIcon,
  TrendingUp as EquityIcon,
  Building2 as PensionIcon,
  Gift as BenefitIcon,
  BadgePercent as AchievementIcon,
  Clock as TimingIcon,
  ShieldCheck as PerformanceIcon,
  Calendar as FrequencyIcon
} from 'lucide-react';

const formatCurrency = (amount, currency = 'GBP') => {
  if (!amount) return null;
  
  const cleanAmount = typeof amount === 'string' 
    ? parseFloat(amount.replace(/[£,]/g, ''))
    : amount;

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(cleanAmount);
};

const Section = ({ title, icon: Icon, children }) => {
  if (!children) return null;

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1.5 }}>
        <Icon size={20} />
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

const SalarySection = ({ salaryInfo }) => {
  if (!salaryInfo) return null;

  const typeLabels = {
    'annual_salary': 'Annual Salary',
    'day_rate': 'Daily Rate',
    'not_currently_receiving': 'Not Currently Receiving'
  };

  return (
    <Section title="Compensation" icon={MoneyIcon}>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {typeLabels[salaryInfo.type]}:{' '}
        <Box component="span" sx={{ fontWeight: 500 }}>
          {formatCurrency(salaryInfo.amount, salaryInfo.currency)}
          {salaryInfo.type === 'day_rate' && ' per day'}
        </Box>
      </Typography>
    </Section>
  );
};

const BonusSection = ({ bonus }) => {
  if (!bonus) return null;

  return (
    <Section title="Bonus Structure" icon={BonusIcon}>
      <List dense disablePadding>
        {bonus.target_percentage && (
          <ListItem disableGutters>
            <ListItemText 
              primary={
                <Typography variant="body2">
                  Target: {bonus.target_percentage}
                </Typography>
              }
            />
          </ListItem>
        )}

        {bonus.personal && (
          <ListItem disableGutters>
            <ListItemText 
              primary={<Typography variant="body2">Personal: {bonus.personal}</Typography>}
            />
          </ListItem>
        )}

        {bonus.company && (
          <ListItem disableGutters>
            <ListItemText 
              primary={<Typography variant="body2">Company: {bonus.company}</Typography>}
            />
          </ListItem>
        )}

        {bonus.other_components && (
          <ListItem disableGutters>
            <ListItemText 
              primary={<Typography variant="body2">Other: {bonus.other_components}</Typography>}
            />
          </ListItem>
        )}

        {bonus.typical_achievement && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <AchievementIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Typical Achievement: {bonus.typical_achievement}</Typography>}
            />
          </ListItem>
        )}

        {bonus.payment_timing && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <TimingIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Payment: {bonus.payment_timing}</Typography>}
            />
          </ListItem>
        )}
      </List>
    </Section>
  );
};

const EquitySection = ({ equity }) => {
  if (!equity) return null;

  return (
    <Section title="Equity" icon={EquityIcon}>
      <List dense disablePadding>
        {equity.type && (
          <ListItem disableGutters>
            <ListItemText 
              primary={<Typography variant="body2">Type: {equity.type}</Typography>}
            />
          </ListItem>
        )}

        {equity.value && (
          <ListItem disableGutters>
            <ListItemText 
              primary={<Typography variant="body2">Value: {equity.value}</Typography>}
            />
          </ListItem>
        )}

        {equity.frequency && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <FrequencyIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Frequency: {equity.frequency}</Typography>}
            />
          </ListItem>
        )}

        {equity.vesting_schedule && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <TimingIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Vesting: {equity.vesting_schedule}</Typography>}
            />
          </ListItem>
        )}

        {equity.performance_conditions && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <PerformanceIcon size={16} />
            </ListItemIcon>
            <ListItemText 
              primary={<Typography variant="body2">Performance Conditions: {equity.performance_conditions}</Typography>}
            />
          </ListItem>
        )}

        {equity.additional_details && (
          <ListItem disableGutters sx={{ mt: 1 }}>
            <ListItemText 
              secondary={equity.additional_details}
            />
          </ListItem>
        )}
      </List>
    </Section>
  );
};

const PensionSection = ({ pension }) => {
  if (!pension) return null;

  const typeLabels = {
    'standard_dc': 'Standard DC Scheme',
    'cash_alternative': 'Cash in Lieu of Pension',
    'executive_pension': 'Executive Pension Scheme',
    'defined_benefit': 'Final Salary Scheme',
    'none': 'No Pension Provision'
  };

  return (
    <Section title="Pension" icon={PensionIcon}>
      <List dense disablePadding>
        <ListItem disableGutters>
          <ListItemText 
            primary={
              <Typography variant="body2">
                Type: {typeLabels[pension.arrangement_type]}
              </Typography>
            }
          />
        </ListItem>

        {pension.employee_contribution && (
          <ListItem disableGutters>
            <ListItemText 
              primary={
                <Typography variant="body2">
                  Employee Contribution: {pension.employee_contribution}
                </Typography>
              }
            />
          </ListItem>
        )}

        {pension.employer_contribution && (
          <ListItem disableGutters>
            <ListItemText 
              primary={
                <Typography variant="body2">
                  Employer Contribution: {pension.employer_contribution}
                </Typography>
              }
            />
          </ListItem>
        )}

        {pension.other_details && (
          <ListItem disableGutters sx={{ mt: 1 }}>
            <ListItemText 
              secondary={pension.other_details}
            />
          </ListItem>
        )}
      </List>
    </Section>
  );
};

const BenefitsSection = ({ benefits }) => {
  if (!benefits?.length) return null;

  return (
    <Section title="Additional Benefits" icon={BenefitIcon}>
      <List dense disablePadding>
        {benefits.map((benefit, index) => (
          <ListItem key={index} disableGutters>
            <ListItemText
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2">{benefit.type}</Typography>
                  {benefit.value && (
                    <Chip 
                      label={benefit.value}
                      size="small"
                      variant="outlined"
                      sx={{ height: 20 }}
                    />
                  )}
                </Box>
              }
              secondary={benefit.description}
            />
          </ListItem>
        ))}
      </List>
    </Section>
  );
};

const RoleRemunerationDetails = ({ remuneration }) => {
  if (!remuneration) return null;

  const { 
    salary_info,
    bonus,
    equity,
    pension,
    additional_benefits
  } = remuneration;

  return (
    <Stack spacing={3} divider={<Divider />}>
      {/* Basic Salary Info */}
      {salary_info && <SalarySection salaryInfo={salary_info} />}

      {/* Bonus Structure */}
      {bonus && <BonusSection bonus={bonus} />}

      {/* Equity/Stock */}
      {equity && <EquitySection equity={equity} />}

      {/* Pension */}
      {pension && <PensionSection pension={pension} />}

      {/* Additional Benefits */}
      {additional_benefits?.length > 0 && (
        <BenefitsSection benefits={additional_benefits} />
      )}
    </Stack>
  );
};

export default RoleRemunerationDetails;