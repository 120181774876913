import React, { useState } from 'react';
import { 
  Typography,
  Stack,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoleScopeDetails from './RoleScopeDetails';
import RoleRemunerationDetails from './RoleRemunerationDetails';
import RoleArrangementsDetails from './RoleArrangementsDetails';
import PreviousExperience from './PreviousExperience';
import ProfessionalSatisfaction from './ProfessionalSatisfaction';
import { hasNonNullValues } from '../utils/hasNonNullValues';

const SectionWithTitle = ({ title, children }) => children && (
  <>
    <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
      {title}
    </Typography>
    {children}
  </>
);

const RoleContent = ({ experience }) => {
  const hasSatisfaction = hasNonNullValues(experience.satisfaction_snapshots);
  const hasScope = hasNonNullValues(experience.scope);
  const hasRemuneration = hasNonNullValues(experience.remuneration_details);
  const hasArrangements = hasNonNullValues(experience.arrangements_and_travel);

  return (
    <Stack spacing={3} divider={<Divider />}>


      {hasSatisfaction && (
        <SectionWithTitle title="Role Satisfaction">
          <ProfessionalSatisfaction satisfaction={experience.satisfaction_snapshots[experience.satisfaction_snapshots.length - 1]} />
        </SectionWithTitle>
      )}

      {hasScope && (
        <SectionWithTitle title="Role Scope">
          <RoleScopeDetails scope={experience.scope} />
        </SectionWithTitle>
      )}

      {hasRemuneration && (
        <SectionWithTitle title="Remuneration">
          <RoleRemunerationDetails remuneration={experience.remuneration_details} />
        </SectionWithTitle>
      )}

      {hasArrangements && (
        <SectionWithTitle title="Location & Travel">
          <RoleArrangementsDetails details={experience.arrangements_and_travel} />
        </SectionWithTitle>
      )}
    </Stack>
  );
};

const CurrentRoles = ({ experiences, currentStage }) => {
  const [expanded, setExpanded] = useState(null);
  
  if (!experiences?.length) return null;

  // Separate current and previous roles
  const currentExperiences = experiences.filter(exp => 
    !exp.end_date || exp.end_date.toLowerCase() === 'current'
  );

  // If no current roles, get the most recent previous role
  const previousExperiences = experiences.filter(exp => 
    exp.end_date && exp.end_date.toLowerCase() !== 'current'
  ).sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

  // Determine which roles to show in detail
  const detailedRoles = currentExperiences.length > 0 
    ? currentExperiences 
    : previousExperiences.length > 0 ? [previousExperiences[0]] : [];

  // Get remaining previous roles (excluding the most recent if it's being shown in detail)
  const remainingPreviousRoles = currentExperiences.length > 0 
    ? previousExperiences 
    : previousExperiences.slice(1);

  const [org, view] = currentStage?.split(' - ') || [];

  const handleChange = (expOrg) => (event, isExpanded) => {
    setExpanded(isExpanded ? expOrg : null);
  };

  return (
    <Stack spacing={2}>
      {/* Show detailed roles (either current roles or most recent role) */}
      {detailedRoles.map((experience) => (
        <Accordion
          key={experience.id}
          expanded={expanded === experience.organisation || experience.organisation === org}
          onChange={handleChange(experience.organisation)}
          disableGutters
          sx={{
            '&:before': { display: 'none' },
            boxShadow: 'none',
            borderRadius: 0
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Stack>
              <Typography variant="subtitle2">
                {experience.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {experience.organisation}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {experience.start_date} - {experience.end_date || 'Present'}
              </Typography>
              {experience.commitment && (
                <Typography variant="body2">
                  {experience.commitment}
                </Typography>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <RoleContent experience={experience} view={view} />
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Show remaining previous roles if there are any */}
      {remainingPreviousRoles.length > 0 && (
        <>
          <Divider />
          <PreviousExperience experiences={remainingPreviousRoles} />
        </>
      )}
    </Stack>
  );
};

export default CurrentRoles;