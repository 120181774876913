import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline, Box, Button, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import Sidebar from '../sidebar/Sidebar';
import ChatContainer from '../chat/ChatContainer';
import { useSocketChat } from '../../hooks/useSocketChat';

const Introduction = () => {
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const inviteCode = Cookies.get('invite_code');
  const socketData = useSocketChat("introduction", {
    requireAuthentication: false,
    inviteCode: inviteCode
  });

  const CustomChatContainer = () => (
    <>
      <ChatContainer socketData={socketData} />
      <Divider sx={{ m: 2 }}>or</Divider>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 1, ml: 2 }}
        onClick={() => loginWithRedirect({
          redirect_uri: '/dashboard'
        })}
      >
        I'm happy, let's go!
      </Button>
    </>
  );

  // Using width calculation only if drawerWidth is defined
  const mainBoxSx = {
    flexGrow: 1,
    width: {
      xs: '100%',
      sm: drawerWidth ? `calc(100% - ${drawerWidth}px)` : '100%'
    },
    marginLeft: {
      xs: 0,
      sm: drawerWidth ? `${drawerWidth}px` : 0
    },
    height: '100vh',
    overflow: 'hidden',
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        content={() => null}
      />
      <Box
        component="main"
        sx={mainBoxSx}
      >
        {inviteCode && <CustomChatContainer />}
      </Box>
    </Box>
  );
};

export default Introduction;