import React, { useRef, useState, useEffect } from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Paper, 
  Typography,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProfileContainer } from './ProfileContainer';
import { useProfileSections } from './useProfileSections';
import DataControlsBanner from './DataControlsBanner';

const ProfileAccordion = ({ socketData }) => {
  const [expanded, setExpanded] = useState(false);
  const [profile, setProfile] = useState(socketData.profile);
  const [error, setError] = useState(socketData.error);
  const activeCardRef = useRef(null);
  
  useEffect(() => {
    setProfile(socketData.profile);
    setError(socketData.error);
  }, [socketData.profile, socketData.error]);

  useEffect(() => {
    if (profile && activeCardRef.current) {
      activeCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [expanded, profile]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const sections = useProfileSections(profile);

  const getCurrentStageIndex = () => {
    const stageKey = profile?.stage;
    if (!stageKey) return -1;

    if (stageKey.includes(' - ')) {
      const [org] = stageKey.split(' - ');
      return sections.findIndex(section => 
        section.stage === 'employment' && 
        section.props.experiences?.some(exp => 
          exp.organisation === org
        )
      );
    }

    return sections.findIndex(section => 
      section.stage === stageKey
    );
  };

  const shouldExpand = (section, stageKey) => {
    if (!stageKey) return false;

    if (stageKey.includes(' - ')) {
      const [org] = stageKey.split(' - ');
      return section.stage === 'employment' && 
        section.props.experiences?.some(exp => 
          exp.organisation === org
        );
    }

    return section.stage === stageKey;
  };

  const currentIndex = getCurrentStageIndex();

  return (
    <ProfileContainer loading={!profile} error={error}>
      <Paper elevation={1}>
        <Box sx={{ p: 2 }}>
          <DataControlsBanner dataControl={profile?.data_control} />
        </Box>
        {sections.map((section, index) => {
          const isActiveSection = index === currentIndex;
          const isExpanded = shouldExpand(section, profile?.stage);
          return (
            <Accordion
              key={section.stage}
              expanded={isExpanded}
              onChange={handleChange(section.stage)}
              ref={isActiveSection ? activeCardRef : null}
              disableGutters
              sx={{
                '&:before': { display: 'none' },
                bgcolor: 'common.white',
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                '&:first-of-type': { borderTop: 'none' }
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <section.component {...section.props} currentStage={profile?.stage} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Paper>
    </ProfileContainer>
  );
};

export default ProfileAccordion;