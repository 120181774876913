import React from 'react';
import { 
  Typography, 
  Stack, 
  Paper,
  Box,
  Link
} from '@mui/material';
import {
  Target as PerformanceIcon,
  FileText as CVIcon,
  UserCheck as ReferencesIcon,
  File as ContractsIcon,
  Brain as PsychometricsIcon,
  Receipt as PayslipsIcon,
  BookOpen as CareerNotesIcon
} from 'lucide-react';

const EmptyState = ({ message }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      py: 3,
      color: 'text.secondary',
      textAlign: 'center'
    }}
  >
    <Typography variant="body2">
      Nothing here yet - to securely add items, email them to{' '}
      <Link 
        href="mailto:vault@nexco.ai"
        color="primary"
        underline="hover"
      >
        vault@nexco.ai
      </Link>.
    </Typography>
  </Box>
);

const VaultSection = ({ title, icon: Icon, children }) => (
  <Paper 
    elevation={1} 
    sx={{ p: 2 }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Icon size={20} style={{ marginRight: '8px', color: 'gray' }} />
      <Typography variant="h6">{title}</Typography>
    </Box>
    {children}
  </Paper>
);

const sections = [
  {
    title: 'CVs',
    icon: CVIcon,
  },
  {
    title: 'References',
    icon: ReferencesIcon,
  },
  {
    title: 'Performance reviews',
    icon: PerformanceIcon,
  },
  {
    title: 'Psychometrics',
    icon: PsychometricsIcon,
  },
  {
    title: 'Contracts',
    icon: ContractsIcon,
  },
  {
    title: 'Payslips & tax documents (e.g. P45)',
    icon: PayslipsIcon,
  },
  {
    title: 'Personal career notes',
    icon: CareerNotesIcon,
  }
];

const PrivateVault = () => {
  return (
    <Box>
      <Stack spacing={2}>
        {sections.map((section) => (
          <VaultSection 
            key={section.title} 
            title={section.title} 
            icon={section.icon}
          >
            <EmptyState />
          </VaultSection>
        ))}
      </Stack>
    </Box>
  );
};

export default PrivateVault;