import { useState, useEffect } from 'react';
import { Alert, Snackbar, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { ChevronUp as ChevronUpIcon } from 'lucide-react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

const ChatLayout = ({ children, error, onErrorClose }) => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
    }}
  >
    <div
      style={{
        flex: 1,
        minHeight: 0,
        display: 'flex',
        gap: '24px',
      }}
    >
      {children}
    </div>
    <Snackbar open={!!error} autoHideDuration={6000} onClose={onErrorClose}>
      <Alert onClose={onErrorClose} severity="error" sx={{ width: '100%' }}>
        {error}
      </Alert>
    </Snackbar>
  </div>
);

const ChatSection = ({ children, isMobile, isSheetOpen }) => (
  <div
    style={{
      flex: isMobile ? 1 : '0 0 66.666%',
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: isMobile && isSheetOpen ? '100px' : 0
    }}
  >
    {children}
  </div>
);

const SidebarSection = ({ children, isMobile }) => {
  if (!children || isMobile) return null;
  
  return (
    <div
      style={{
        flex: '0 0 33.333%',
        minHeight: 0,
      }}
    >
      {children}
    </div>
  );
};

const FloatingButton = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'fixed',
      bottom: 16,
      right: 16,
      backgroundColor: 'primary.main',
      color: 'white',
      '&:hover': {
        backgroundColor: 'primary.dark',
      },
      width: 48,
      height: 48,
      boxShadow: 2,
    }}
  >
    <ChevronUpIcon />
  </IconButton>
);

const ChatAndArtifactsContainer = ({ 
  ChatComponent,
  ProfileComponent = null,
  initialError = null
}) => {
  const [containerError, setContainerError] = useState(initialError);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setContainerError(null);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsSheetOpen(false);
    }
  }, [isMobile]);

  return (
    <ChatLayout error={containerError} onErrorClose={handleErrorClose}>
      <ChatSection isMobile={isMobile} isSheetOpen={isSheetOpen}>
        <ChatComponent 
          onError={(error) => setContainerError(error)} 
        />
      </ChatSection>
      
      <SidebarSection isMobile={isMobile}>
        {ProfileComponent && <ProfileComponent />}
      </SidebarSection>

      {isMobile && ProfileComponent && (
        <>
          <FloatingButton onClick={() => setIsSheetOpen(true)} />
          
          <BottomSheet
            open={isSheetOpen}
            onDismiss={() => setIsSheetOpen(false)}
            snapPoints={({ maxHeight }) => [
              maxHeight * 0.25,
              maxHeight * 0.5,
              maxHeight * 0.9
            ]}
            defaultSnap={({ snapPoints }) => snapPoints[1]}
            expandOnContentDrag
            header={
              <div style={{ width: '100%', height: '6px', padding: '10px' }}>
                <div 
                  style={{ 
                    width: '48px', 
                    height: '4px', 
                    backgroundColor: '#E0E0E0',
                    borderRadius: '2px',
                    margin: '0 auto'
                  }} 
                />
              </div>
            }
          >
            <div style={{ padding: '20px' }}>
              <ProfileComponent />
            </div>
          </BottomSheet>
        </>
      )}
    </ChatLayout>
  );
};

export default ChatAndArtifactsContainer;