import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStatus } from '../../hooks/useAuthStatus';
import Cookies from 'js-cookie';

export const InviteRoute = ({ children }) => {
  const { isLoading, hasValidAccount, shouldWaitlist } = useAuthStatus();
  const location = useLocation();
  const inviteCode = Cookies.get('invite_code');

  // Handle loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // If user already has an account, redirect to onboarding
  if (hasValidAccount) {
    return <Navigate to="/dashboard" replace />;
  }

  // If no invite code and should be waitlisted, send to waitlist
  if (!inviteCode && shouldWaitlist) {
    return <Navigate to="/waitlist" replace />;
  }

  // If no invite code and not on waitlist, send to login
  if (!inviteCode) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Has invite code - allow access to signup flow
  return children;
};